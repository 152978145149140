(function() {
  "use strict";

  var jQuery = $;

  // Detect mobile OS
  function detectOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i )) {
      jQuery('body').addClass('ios');
    }
    if (userAgent.match( /Android/i )) {
      jQuery('body').addClass('android');
    }
  }
  detectOS();

    if (jQuery('body').hasClass('single-nyheter')) {
        jQuery('#spinner').hide();
     }
  // Spinner (loading indicator)

    var printImg = jQuery('#print-image'),
        webImg = printImg.attr('src');

    jQuery('<img/>').attr('src', webImg).load(function() {
      jQuery(this).remove(); // Prevent memory leaks
      jQuery('#main-image').css('background-image', 'url(' + webImg + ')');
      jQuery('#spinner').hide();
    });
  

  // Rotating images on home->start
  var seconds = 4000,
      step = 0,
      limit = 2;

  jQuery('#page-one').addClass('image-' + step);
  setInterval(function() {
    jQuery('#page-one').removeClass('image-' + step);
    step = (step > limit) ? 0 : step + 1;
    jQuery('#page-one').addClass('image-' + step);
  }, seconds);

  // If home.php
  if (jQuery('body').hasClass('home')) {
    jQuery('html').addClass('i-am-home');

    // Fullscreen scrolling
    if (jQuery('body').width() > 1023) {
      jQuery(document).ready(function() {
        jQuery('#fullpage').fullpage({
          menu: '#nav',
          anchors:['start', 'om', 'prova', 'fragor-och-svar', 'kontakt'],
          verticalCentered: false,
          slidesNavigation: true,
          slidesNavPosition: 'bottom',
          afterLoad: function(anchorLink, index) {
            if (index === 1) {
              jQuery('#home-header').addClass('is-start').removeClass('not-start');
            } else {
              jQuery('#home-header').addClass('not-start').removeClass('is-start');
            }
          }
        });
      });
    }

    // Q & A stuffz
    var question = jQuery('.question');

    question.click(function() {
      jQuery(this).parent().addClass('active').siblings().removeClass('active');
    });
  }

  // Login stuff
  jQuery('.login-submit #wp-submit').attr('value', 'Starta tjänsten');

  var loginToggle = jQuery('.login'),
      loginForm = jQuery('.login-form'),
      loginFormWrapper = jQuery('#login-form-wrapper'),
      lostPasswordWrapper = jQuery('#lost-password-wrapper');

  loginToggle.click(function() {
    lostPasswordWrapper.hide();
    loginFormWrapper.show();
    loginToggle.toggleClass('closed open');
    loginForm.toggleClass('closed open');
    jQuery('.login-form #user').focus();
  });

  // Contact form on home.php
  jQuery(function() {
    var form = jQuery('#ajax-contact'),
        formMessages = jQuery('#form-messages');

    jQuery(form).submit(function(event) {
      event.preventDefault();

      var formData = jQuery(form).serialize();

      jQuery.ajax({
        url: jQuery(form).attr('action'),
        type: 'POST',
        data: formData
     }).done(function(response) {
        form.hide();
        jQuery(formMessages).removeClass('error');
        jQuery(formMessages).addClass('success');
        jQuery(formMessages).text(response);
        jQuery('#name, #email, #company').val('');
      }).fail(function(data) {
        jQuery(formMessages).removeClass('success');
        jQuery(formMessages).addClass('error');
        if (data.responseText !== '') {
          form.hide();
          jQuery(formMessages).text(data.responseText);
        } else {
          form.hide();
          jQuery(formMessages).text('Ojdå! Något gick fel och din anmälan kunde inte skickas. Försök igen.');
        }
      });
    });
  });

  // Cookies
  var createCookie = function(name, value, days) {
    var expires;

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)===' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  function eraseCookie(name) {
    createCookie(name, '', -1);
  }

  // Store current url
  var currentURL = window.location.href;

  if (jQuery('body').hasClass('single') && jQuery('body').hasClass('logged-in')) {
    createCookie('lastVisited', currentURL, 365);
  }

  var cookies = jQuery('#cookie-notice'),
      allowCookies = jQuery('#allow-cookies'),
      terms = jQuery('#terms-reminder'),
      termsReminder = jQuery('#ok-i-know'),
      bw = jQuery('.bw');

  // Cookie notice
  allowCookies.click(function() {
    cookies.hide();
    bw.removeClass('bw');
    createCookie('allowCookies', 'yes', 365);
    createCookie('termsReminder', 'yes', 14);
  });

  // Terms reminder
  termsReminder.click(function() {
    terms.hide();
    bw.removeClass('bw');
    createCookie('termsReminder', 'yes', 14);
  });

  // Levels stuff
  var mainMeals = jQuery('#menu ul > li.main-meals > ul.sub-menu > li.menu-item-has-children > a'),
      smallMeals = jQuery('#menu ul > li.small-meals > ul.sub-menu > li.menu-item-has-children > a'),
      catMeals = jQuery('.category .posts li a');

  // Menu related
  mainMeals.each(function() {
    var text = jQuery(this).text();
    var textAfterHash = text.substr(text.indexOf('|'));
    if (jQuery(this).is(':contains("Nivå")')) {
      jQuery(this).html(text.replace(textAfterHash, '<span>' + textAfterHash + '</span>' ));
    }
  });

  smallMeals.each(function() {
    var text = jQuery(this).text();
    var textAfterHash = text.substr(text.indexOf('|'));
    if (jQuery(this).is(':contains("Nivå")')) {
      jQuery(this).html(text.replace(textAfterHash, '<span>' + textAfterHash + '</span>' ));
    }
  });

  jQuery('#menu ul > li.main-meals > ul.sub-menu > li.menu-item-has-children > a:contains(|)').each(function() {
    jQuery(this).html(
      jQuery(this).html().replace('|','')
    );
  });

  jQuery('#menu ul > li.small-meals > ul.sub-menu > li.menu-item-has-children > a:contains(|)').each(function() {
    jQuery(this).html(
      jQuery(this).html().replace('|','')
    );
  });

  // Category related
  catMeals.each(function() {
    var text = jQuery(this).text();
    var textAfterHash = text.substr(text.indexOf('|'));
    if (jQuery(this).is(':contains("Nivå")')) {
      jQuery(this).html(text.replace(textAfterHash, '<span>' + textAfterHash + '</span>' ));
    }
  });

  jQuery('.category .posts li a:contains(|)').each(function() {
    jQuery(this).html(
      jQuery(this).html().replace('|','')
    );
  });

  // Toggle content on single.php
  var bar = jQuery('#bar'),
      contentToggle = jQuery('#toggle-content'),
      content = jQuery('#content'),
      metaToggle = jQuery('#toggle-meta'),
      graph = jQuery('#energy-graph');

  contentToggle.click(function() {
    bar.toggleClass('closed open');
    metaToggle.toggleClass('closed open');
    contentToggle.toggleClass('closed open');
    content.toggleClass('closed open');
    graph.toggleClass('closed open');
  });

  // Show content on single.php
  function showContent() {
    bar.addClass('open').removeClass('closed');
    contentToggle.addClass('open').removeClass('closed');
    metaToggle.toggleClass('closed open');
    metaToggle.addClass('open').removeClass('closed');
    content.addClass('open').removeClass('closed');
    graph.addClass('open').removeClass('closed');
  }

  // Hide content on single.php
  function hideContent() {
    bar.addClass('closed').removeClass('open');
    contentToggle.addClass('closed').removeClass('open');
    content.addClass('closed').removeClass('open');
    graph.addClass('closed').removeClass('open');
  }

  // Toggle UI on single.php
  var uiToggle = jQuery('#toggle-ui'),
      ui = jQuery('.ui'),
      elements = jQuery('#bar, #toggle-content, #content, #energy-graph'),
      fullscreenImage = jQuery('#main-image.fullscreen-image'),
      watermark = jQuery('#watermark'),
      printbutton = jQuery('#print'),
      url = jQuery('#url');

  uiToggle.click(function() {
    if (elements.hasClass('open')) {
      elements.removeClass('open');
      elements.addClass('closed');
    }
    bar.removeClass('tags-open').addClass('tags-closed');
    tagsToggle.removeClass('open').addClass('closed');
    tags.removeClass('open').addClass('closed');
    uiToggle.toggleClass('inactive active');
    fullscreenImage.toggleClass('fullscreen');
    ui.toggleClass('show hide');
    watermark.toggle();
    printbutton.toggle();
    url.toggle();
  });

  // Toggle favorites
  var favsToggle = jQuery('#toggle-favorites'),
      favs = jQuery('#favorites'),
      favsList = jQuery('#favorites-list'),
      favsListItems = jQuery('#favorites-list li'),
      favsListCount = favsListItems.length,
      favsListMath = favsListCount * 4,
      favsListWidth = favsListMath * 10;

  favsToggle.click(function() {
    favsList.css('width', favsListWidth + 'rem');
    favsToggle.toggleClass('closed open');
    favs.toggleClass('closed open');
  });

  // Visual feedback for save/remove favorite
  var favsAction = window.location.href,
      whichAction = favsAction.substr(favsAction.lastIndexOf('?') + 1),
      favsFeedback = jQuery('#favorite-feedback'),
      favSaved = favsFeedback.find('.save'),
      favRemoved = favsFeedback.find('.remove');

  if (whichAction === 'save=true') {
     favsFeedback.addClass('active');
     favSaved.addClass('active');
  }

  if (whichAction === 'remove=true') {
     favsFeedback.addClass('active');
     favRemoved.addClass('active');
  }

  // Toggle tags on single.php
  var tagsToggle = jQuery('#toggle-tags'),
      tags = jQuery('#tags');

  tagsToggle.click(function() {
    bar.toggleClass('tags-closed tags-open');
    tagsToggle.toggleClass('closed open');
    tags.toggleClass('closed open');
  });

  // Make whole div clickable
  jQuery('.clickable').click(function() {
    window.location = jQuery(this).find('h2 a').attr('href');
    return false;
  });

  // Keyboard functionality
  jQuery(document.documentElement).keyup(function (e) {
    var body = jQuery('body');

    // Prev post
    if (e.keyCode === 37) {
      if (body.hasClass('single')) {
        if (jQuery('.prev:not(.disabled)').length) {
          window.location = jQuery('.prev').attr('href');
        }
      }
    }

    // Next post
    if (e.keyCode === 39) {
      if (body.hasClass('single')) {
        if (jQuery('.next:not(.disabled)').length) {
          window.location = jQuery('.next').attr('href');
        }
      }
    }

    // Show content
    if (e.keyCode === 38) {
      if (body.hasClass('single')) {
        showContent();
      }
    }

    // Hide content
    if (e.keyCode === 40) {
      if (body.hasClass('single')) {
        hideContent();
      }
    }

    // ESCAPE!
    if (e.keyCode === 27) {
      if (body.hasClass('single')) {
        hideContent();
        bar.addClass('tags-closed').removeClass('tags-open');
        tagsToggle.addClass('closed').removeClass('open');
        tags.addClass('closed').removeClass('open');
        favsToggle.addClass('closed').removeClass('open');
        favs.addClass('closed').removeClass('open');
        elements.addClass('closed').removeClass('open');
        uiToggle.addClass('inactive').removeClass('active');
        fullscreenImage.removeClass('fullscreen');
        ui.addClass('show').removeClass('hide');
        watermark.hide();
        url.hide();
      }
    }
  });

  // Search init stuff
  var search = jQuery('.search-field'),
      submit = jQuery('.search-submit'),
      searchOver = jQuery('#search');

  search.focus(function() {
    search.addClass('init').removeClass('exit');
    submit.addClass('init').removeClass('exit');
    searchOver.addClass('init').removeClass('exit');
  });

  submit.click(function() {
    search.addClass('init').removeClass('exit');
    submit.addClass('init').removeClass('exit');
    searchOver.addClass('init').removeClass('exit');
  });

  jQuery(document).mouseup(function(e) { // TODO: Might wanna tweak this shit...
    var container = jQuery('#search');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      search.addClass('exit').removeClass('init');
      submit.addClass('exit').removeClass('init');
      searchOver.addClass('exit').removeClass('init');
    }
  });

  // CUSTOM SPECIAL STUFF FOR HANDPICKED NAV ORDER ULTRA HAXXX
  var handpickedCategory = jQuery('#menu-toppmeny > .handpicked'),
      handpickedItem = jQuery('#menu-toppmeny > .handpicked .sub-menu li'),
      clickedHandpickedCategory = jQuery('#menu-toppmeny > .handpicked .sub-menu .menu-item-object-category > a'),
      clickedHandpickedItem = jQuery('#menu-toppmeny > .handpicked .menu-item-object-post a'),
      regularCategory = jQuery('#menu-toppmeny > li:not(.handpicked)'),
      regularItem = jQuery('#menu-toppmeny > li:not(.handpicked) .sub-menu li'),
      handpickedCategoryPageItem = jQuery('.category-rekommenderade .posts.parent .cat-item');

  handpickedCategoryPageItem.click(function() {
    var categoryName = jQuery(this).text(),
        matchedItem = jQuery('ul li[class*="current"].handpicked ul li:contains("' + categoryName + '")'),
        matchedItemID = matchedItem.attr('id');
    createCookie('handpickedParentID', matchedItemID, 365);
  });

  handpickedCategory.click(function() {
    createCookie('fromHandpicked', 'yes', 365);
  });

  handpickedItem.click(function() {
    createCookie('fromHandpicked', 'yes', 365);
  });

  clickedHandpickedCategory.click(function() {
    var parentID = jQuery(this).parent().attr('id');
    createCookie('handpickedParentID', parentID, 365);
  });

  clickedHandpickedItem.click(function() {
    var parentID = jQuery(this).parent().parent().parent().attr('id');
    createCookie('handpickedParentID', parentID, 365);
  });

  regularCategory.click(function() {
    eraseCookie('fromHandpicked');
    eraseCookie('handpickedParentID');
  });

  regularItem.click(function() {
    eraseCookie('fromHandpicked');
    eraseCookie('handpickedParentID');
  });

  var isHandpicked = readCookie('fromHandpicked'),
      parentValue = readCookie('handpickedParentID');

  // IF NOT handpicked
  if (isHandpicked === null) {

    // Open and close main menu
    var openMenu = jQuery('#open-menu'),
        closeMenu = jQuery('#close-menu'),
        menu = jQuery('#menu');

    openMenu.click(function() {
      menu.addClass('open');
      menu.find('ul li[class*="current"]:not(.handpicked)').addClass('show');
        jQuery('#meny-background').show();
    });

    closeMenu.click(function() {
      menu.removeClass('open');
    });

    // Current menu item stuff
    var mother = jQuery('#menu ul > li[class*="current"]:not(.handpicked)'),
        item = jQuery('#menu ul > li:not([class*="current"]) *');

    item.hover(function() {
      if ( ! jQuery(this).closest(mother).hasClass('.show') ) {
        menu.find('ul > li.show').removeClass('show');
      }
    });

    // Prev/Next navigation on single.php
    var currentMenuItem = jQuery('#menu-toppmeny > li:not(.handpicked) ul.sub-menu li.current-menu-item'),
        prevMenuItem = currentMenuItem.closest('li').prev().find('a').attr('href'),
        nextMenuItem = currentMenuItem.closest('li').next().find('a').attr('href'),
        prevLink = jQuery('.arrow-nav .prev'),
        nextLink = jQuery('.arrow-nav .next');

    // PREV
    if (prevMenuItem) {
      prevLink.attr('href', prevMenuItem);
      prevLink.removeClass('disabled');
    }

    // NEXT
    if (nextMenuItem) {
      nextLink.attr('href', nextMenuItem);
      nextLink.removeClass('disabled');
    }
  }

  // IF IS handpicked
  if (isHandpicked === 'yes') {

    // Open and close main menu
    var openHandpickedMenu = jQuery('#open-menu'),
        closeHandpickedMenu = jQuery('#close-menu'),
        handpickedMenu = jQuery('#menu');

    openHandpickedMenu.click(function() {
      handpickedMenu.addClass('open');
      handpickedMenu.find('ul li[class*="current"].handpicked').addClass('show');
      handpickedMenu.find('ul li[class*="current"].handpicked ul li#' + parentValue + '[class*="current"]').addClass('show');
      handpickedMenu.find('ul li[class*="current"].handpicked ul li#' + parentValue + '[class*="current"] ul li[class*="current"]').addClass('show');
    });

    closeHandpickedMenu.click(function() {
      handpickedMenu.removeClass('open');
    });

    // Current menu item stuff
    var currentHandpickedMother = jQuery('#menu ul > li[class*="current"].handpicked'),
        currentHandpickedItem = jQuery('#menu ul > li:not([class*="current"]) *');

    currentHandpickedItem.hover(function() {
      if ( ! jQuery(this).closest(currentHandpickedMother).hasClass('.show') ) {
        handpickedMenu.find('ul > li.show').removeClass('show');
      }
    });

    // Prev/Next navigation on single.php
    var currentHandpickedMenuItem = jQuery('#menu-toppmeny > li.handpicked ul.sub-menu li#' + parentValue + ' li.current-menu-item'),
        prevHandpickedMenuItem = currentHandpickedMenuItem.closest('li').prev().find('a').attr('href'),
        nextHandpickedMenuItem = currentHandpickedMenuItem.closest('li').next().find('a').attr('href'),
        prevHandpickedLink = jQuery('.arrow-nav .prev'),
        nextHandpickedLink = jQuery('.arrow-nav .next');

    // PREV
    if (prevHandpickedMenuItem) {
      prevHandpickedLink.attr('href', prevHandpickedMenuItem);
      prevHandpickedLink.removeClass('disabled');
    }

    // NEXT
    if (nextHandpickedMenuItem) {
      nextHandpickedLink.attr('href', nextHandpickedMenuItem);
      nextHandpickedLink.removeClass('disabled');
    }
  }

  // Expand search box if search has value
  if (jQuery('.search-field').val()) {
    jQuery('.search-field, .search-submit, #search').removeClass('exit').addClass('init');
  }
})();
